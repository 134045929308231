module.exports = [{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.thaheifers.com"},
    },{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXXXXX-X"},
    },{
      plugin: require('/opt/build/repo/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
